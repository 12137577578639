<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20">
      <div class="mr-40">
        评级类型：
        <span
            class="font-weight-bold"
            :class="rateServiceTypeStatusClassType[orderProductInfoVo.serviceType]"
        >{{ computedRateServiceTypeStatusListMapText(orderProductInfoVo.serviceType) }}</span>
      </div>
      <div class="mr-40" v-if="orderProductInfoVo.extendDaySize">
        延长天数：
        <span class="font-weight-bold color-dq-blue">{{ orderProductInfoVo.extendDaySize }}</span>
      </div>
      <div class="mr-40">
        评级状态：
        <span
            class="font-weight-bold"
            :class="rateServiceStatusClassType[orderProductInfoVo.ratingStatus]"
        >
        {{ computedRateServiceStatusMapTypeText(orderProductInfoVo.ratingStatus) }}</span>
      </div>
      <div class="mr-40">
        评级费：
        <span class="font-weight-bold color-red">￥{{ ratingInfoVo.ratingMoney || 0 }}</span>
        【<span class="font-weight-bold" :class="payTypeListClassType[ratingInfoVo.ratingPayType]">
          {{ computedPayTypeListMapText(ratingInfoVo.ratingPayType) }}</span>】
      </div>
      <div class="mr-40">
        标版费：
        <span class="font-weight-bold color-red">
          ￥{{ ratingInfoVo.biaobanMoney || 0 }}
          <span v-if="ratingInfoVo.biaobanPayStatus === 6" class="color-gary">（已退回）</span>
        </span>
        【<span class="font-weight-bold" :class="payTypeListClassType[ratingInfoVo.biaobanPayType]">
          {{ computedPayTypeListMapText(ratingInfoVo.biaobanPayType) }}</span>】
      </div>
    </div>
    <div class="flex-start-center flex-wrap mt-20">
      <a-popconfirm title="确定要取消评级转为直发？" @confirm="handleConfirmCancelRateService(orderProductInfoVo.id)">
        <a-button class="mr-40" type="danger" v-if="orderProductInfoVo.orderRatingServiceRank">取消评级转直发</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定要退回标版费？" @confirm="handleReturnRateServiceVersionPrice(orderProductInfoVo.id)">
        <a-button class="mr-40" type="danger" v-if="ratingInfoVo.biaobanMoney">退标版费</a-button>
      </a-popconfirm>
      <a-button
          class="mr-40"
          type="danger"
          v-if="!ratingInfoVo.biaobanMoney"
          @click="handleChangeRateServiceVersionPrice(orderProductInfoVo.id)"
      >修改标版费</a-button>
    </div>
<!--  修改标版费  -->
    <EditorRateServiceVersionPricePopup ref="editorRateServiceVersionPricePopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {
  rateServiceTypeStatusClassType,
  rateServiceTypeStatusListMapText
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import {
  computedRateServiceStatusMapType,
  payTypeListClassType, payTypeListMapType,
  rateServiceStatusClassType
} from "@/views/cmsPage/dqPlatform/_data"
import {
  postCancelRateServiceToSendBuyerApi,
  postReturnRateServiceVersionPriceApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import EditorRateServiceVersionPricePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/RateAboutInfo/_components/EditorRateServiceVersionPricePopup/index.vue"

export default {
  components: {EditorRateServiceVersionPricePopup},
  props: ['orderProductInfoVo', 'orderId', 'ratingInfoVo'],
  emits: ['success'],
  data() {
    return {
      rateServiceTypeStatusClassType, // 评级服务类型
      rateServiceStatusClassType, // 评级状态样式
      payTypeListClassType, // 支付类型样式
    }
  },
  computed: {
    computedPayTypeListMapText() { // 支付类型
      return (value) => payTypeListMapType(value)
    },
    computedRateServiceTypeStatusListMapText() { // 评级类型
      return (value) => rateServiceTypeStatusListMapText(value)
    },
    computedRateServiceStatusMapTypeText() { // 评级状态
      return value => computedRateServiceStatusMapType(value)
    },
  },
  methods: {
    /** 修改标版费 */
    handleChangeRateServiceVersionPrice(orderId) {
      this.$refs.editorRateServiceVersionPricePopupEl.show(orderId)
    },
    /** 退标版费 */
    async handleReturnRateServiceVersionPrice(orderId) {
      this.$loading.show()
      const res = await postReturnRateServiceVersionPriceApi({orderId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('退款成功')
      this.handleSuccess()
    },
    /** 确定取消评级转直发 */
    async handleConfirmCancelRateService(orderId) {
      this.$loading.show()
      const res = await postCancelRateServiceToSendBuyerApi({orderId})
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info(res.message || '成功')
      this.handleSuccess()
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>