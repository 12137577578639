<template>
  <div>
    <div class="flex-colum-start-start flex-wrap mt-20">
      <div class="flex-start-center">
        订单逆向退款状态：
        <span class="color-red font-weight-bold" :class="orderReturnPriceStatusClassType[orderProductInfoVo.refundStatus]">
          {{orderProductInfoVo.refundStatusName}}
        </span>
        <span
            class="flex-start-center font-weight-bold"
            v-if="orderProductInfoVo.orderReturnRemainTime"
        >
          （<BaseCountdownCom ref="baseCountdownComByReturnEl" :remaining-time="orderProductInfoVo.orderReturnRemainTime"/>）
        </span>
      </div>
      <div class="mt-10">
        货物状态：
        <span class="color-black font-weight-bold" :class="orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].textColor : ''">
          {{ orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].goodsStatus : '-' }}
        </span>
      </div>
      <div class="mt-10">
        退款理由：
        <span class="color-red font-weight-bold">
          {{ orderReturnVo.returnsTypeValue }}
        </span>
        <a-button class="ml-20" type="danger" size="small" @click="handleChangeReturnPriceReason(orderReturnVo.returnsType, orderReturnVo.returnsTypeValue)">修改退款理由</a-button>
      </div>
      <div class="mt-10">
        退款类型：
        <span class="color-red font-weight-bold">
          {{ orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].returnPriceType : '-' }}
        </span>
      </div>
      <div class="mt-10" v-if="orderReturnVo.files">
        买家退款凭证：
        <div class="w-50 h-50 flex-center-center" v-for="(img, index) in orderReturnVo.files" :key="index">
          <img class="w-100-w h-100-h" :src="img" alt="" @click="handleShowBigImg(orderReturnVo.files, index)">
        </div>
      </div>
      <div class="mt-10" v-if="orderReturnVo.remark">
        买家退款补充描述：
        <span class="color-black font-weight-bold">
          {{ orderReturnVo.remark }}
        </span>
      </div>
<!--   买家，卖家，赔付信息   -->
      <div class="mt-30 flex-start-start">
        <div class="flex-colum-start-start">
          <div class="font-weight-bold color-black">买家赔付信息</div>
          <div class="flex-start-center mt-10" v-for="(item, index) in buyerCompensationInformation" :key="index">
            <div class="flex-start-center font-weight-bold w-250">{{index}}</div>
            <div class="flex-start-center font-weight-bold color-red w-50">{{item}}</div>
          </div>
        </div>
        <div class="flex-colum-start-start ml-100">
          <div class="font-weight-bold color-black">卖家赔付信息</div>
          <div class="flex-start-center mt-10" v-for="(item, index) in sellerCompensationInformation" :key="index">
            <div class="flex-start-center font-weight-bold w-250">{{index}}</div>
            <div class="flex-start-center font-weight-bold color-red w-50">{{item}}</div>
          </div>
        </div>
      </div>
    </div>
<!--  修改退款理由  -->
    <ChangeReturnPriceReasonPopup ref="changeReturnPriceReasonPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import BaseCountdownCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseCountdownCom/index.vue"
import {orderReturnPriceStatusClassType} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import ChangeReturnPriceReasonPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderReturnPriceInfo/_components/ChangeReturnPriceReasonPopup/index.vue"
import _returnOrderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderDetailMixin"

export default {
  emits: ['success'],
  mixins: [_returnOrderDetailMixin],
  components: {BaseCountdownCom, ChangeReturnPriceReasonPopup},
  props: ['orderId', 'orderReturnVo', 'orderProductInfoVo', 'buyerCompensationInformation', 'sellerCompensationInformation'],
  data() {
    return {
      orderReturnPriceStatusClassType, // 退款状态
      receiptTypeMapText: {
        goodsreceived: {
          goodsStatus: '我已收到货',
          returnPriceType: '退货退款',
          textColor: 'color-green'
        },
        goodsnotreceived: {
          goodsStatus: '我未收到货',
          returnPriceType: '仅退款',
          textColor: 'color-red'
        }
      }
    }
  },
  methods: {
    /** 修改退款理由 */
    handleChangeReturnPriceReason(type, value) {
      this.$refs.changeReturnPriceReasonPopupEl.show({
        orderId: this.orderId,
        returnsType: type,
        returnsTypeValue: value
      })
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>