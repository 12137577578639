<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单编号   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div class="font-weight-bold" :class="orderTypeClassType[row.orderKind]">{{ computedOrderTypeListMapText(row.orderKind) }}</div>
        <div class="mt-5 font-weight-bold">【<span class="color-blue">{{ row.orderNo }}</span>】</div>
        <div class="mt-5" v-if="row.remarkNewest.id">客服备注：<span class="color-black font-weight-bold">{{ row.remarkNewest.content }}</span></div>
      </div>
<!--   产品名称   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div
            v-if="row.productReviewStatus"
            :class="orderProductLevelClassType[row.productReviewStatus]"
        >【{{ computedOrderProductLevelMapText(row.productReviewStatus) }}】</div>
        <div class="mt-10 font-weight-bold color-black">{{ row.productName }}</div>
      </div>
<!--   快照   -->
      <div slot="itemProductImgListSlot" slot-scope="row" class="flex-start-center flex-wrap">
        <div v-for="(item, index) in row.coverImages" :key="index" v-lazy-container="{ selector: 'img' }">
          <img
              @click="handleShowBigImg(row.coverImages, index)"
              class="w-100 h-100"
              :data-src="item"
          />
        </div>
      </div>
<!--   订单金额   -->
      <div slot="itemOrderPriceAndPayTypeSlot" slot-scope="row">
        <div class="font-weight-bold color-red">￥{{ row.returnsAmount }}</div>
      </div>
<!--   订单状态   -->
      <div slot="itemOrderStatusSlot" slot-scope="row">
        <div class="font-weight-bold">订单状态【<span :class="orderStatusTypeListClassType[row.orderStatus]">{{ computedOrderStatusTypeListMapText(row.orderStatus) || '未知' }}</span>】</div>
        <div class="mt-5 font-weight-bold">退单状态【<span :class="orderReturnPriceStatusClassType[row.refundStatus]">{{ computedOrderReturnPriceStatusMapText(row.refundStatusName) || '暂无' }}</span>】</div>
        <div class="mt-5 font-weight-bold">退款状态【<span :class="orderReturnPriceStatusClassType[row.returnsReviewStatus]">{{ row.returnsReviewStatusName || '暂无' }}</span>】</div>
      </div>
<!--   卖家，买家信息   -->
      <div slot="itemBuyerInfoOrSellerInfoSlot" slot-scope="row">
        <div class="font-weight-bold">买家信息【<span class="color-green">{{ row.buyerUserName + '---' + row.buyerUserId }}</span>】</div>
        <div class="mt-5 font-weight-bold">卖家信息【<span class="color-blue">{{ row.sellerUserName + '---' + row.sellerUserId }}</span>】</div>
        <div class="mt-5">
          <a-button @click="handleShowUserChatList(row)">查看聊天记录</a-button>
        </div>
      </div>
<!--   客服介入   -->
      <div slot="itemServiceJoinStatusSlot" slot-scope="row">
        <div class="font-weight-bold" :class="serviceJoinTypeClassType[row.isServiceInterveneDesc]">{{ computedServiceJoinTypeListMapText(row.isServiceInterveneDesc) }}</div>
        <div class="mt-5" v-if="row.isServiceInterveneDesc">
          <a-button @click="handleShowVoucherList(row)">查看举证/留言列表</a-button>
        </div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button type="primary" @click="handleShowOrderDetail(row)">订单详情</a-button>
      </div>
    </a-table>
<!--  聊天记录  -->
    <UserChatListModel ref="userChatListModelEl"/>
<!--  查看凭证留言信息  -->
    <ReturnOrderUserVoucherListPopup ref="returnOrderUserVoucherListPopupEl"/>
<!--  订单详情  -->
    <ReturnOrderDetailPopup ref="returnOrderDetailPopupEl" @success="handleSuccess" />
  </div>
</template>
<script>
import {
  orderReturnTableColumns, serviceJoinTypeClassType,
  serviceJoinTypeListMapText
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_data"
import {
  orderProductLevelClassType,
  orderProductLevelMapText,
  orderReturnPriceStatusClassType, orderReturnPriceStatusMapText,
  orderStatusTypeListClassType,
  orderStatusTypeListMapText,
  orderTypeClassType,
  orderTypeListMapText,
  rateServiceTypeStatusClassType,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import UserChatListModel from "@/views/cmsPage/dqPlatform/components/UserChatListModel.vue"
import ReturnOrderUserVoucherListPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderUserVoucherListPopup/index.vue"
import ReturnOrderDetailPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/index.vue"

export default {
  components: {UserChatListModel, ReturnOrderUserVoucherListPopup, ReturnOrderDetailPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      serviceJoinTypeClassType, // 客服介入
      orderProductLevelClassType, // 产品等级，
      orderTypeClassType, // 订单类型样式
      orderStatusTypeListClassType, // 订单状态
      rateServiceTypeStatusClassType, // 评级状态
      orderReturnPriceStatusClassType, // 退款状态
      tableList: orderReturnTableColumns,
    }
  },
  computed: {
    computedOrderProductLevelMapText() { // 等级
      return (value) => orderProductLevelMapText(value)
    },
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
    computedOrderStatusTypeListMapText() { // 订单状态
      return (value) => orderStatusTypeListMapText(value)
    },
    computedOrderReturnPriceStatusMapText() { // 退款状态
      return (value) => orderReturnPriceStatusMapText(value)
    },
    computedServiceJoinTypeListMapText() { // 客服介入
      return (value) => serviceJoinTypeListMapText(value)
    },
  },
  methods: {
    /** 订单详情 */
    handleShowOrderDetail(row) {
      this.$refs.returnOrderDetailPopupEl.show(row)
    },
    /** 查看用户的举证列表 */
    handleShowVoucherList(row) {
      this.$refs.returnOrderUserVoucherListPopupEl.show(row.orderId)
    },
    /** 查看聊天记录 */
    handleShowUserChatList(row) {
      this.$refs.userChatListModelEl.show(row.groupId, row.sellerUserId, row.buyerUserId)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 查看大图 */
    handleShowBigImg(urls, index) {
      const tempUrls = urls.map(el => ({img_url: el}))
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: tempUrls,
          current: index,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>