var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "修改标版费" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "评级标版费" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: {
                  allowClear: "",
                  type: "number",
                  placeholder: "评级标版费"
                },
                model: {
                  value: _vm.params.price,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "price", $$v)
                  },
                  expression: "params.price"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "添加备注" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  autoSize: "",
                  placeholder:
                    "此备注信息会展示在评级鉴定结果的页面，前端也会展示在订单历史记录里"
                },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }