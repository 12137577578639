var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderProductInfoVo.refundStatus == null ||
      _vm.orderProductInfoVo.refundStatus === -12
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _vm._l(_vm.tagDayList, function(tag, index) {
                return _c(
                  "a-tag",
                  {
                    key: index,
                    staticClass: "cur-pot-not-line mr-20",
                    attrs: { color: tag.selected ? "blue" : "" },
                    on: {
                      click: function($event) {
                        return _vm.handleChangeTag(tag.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(tag.name))]
                )
              }),
              _c(
                "a-popconfirm",
                {
                  attrs: { title: "确认延长收货么?" },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmExtendTakeOver(
                        _vm.orderProductInfoVo.id
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("确认延长收货")
                  ])
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      [1, 2, 3, 4].includes(_vm.orderProductInfoVo.status)
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c("a-textarea", {
                staticClass: "w-400 mr-20",
                attrs: { placeholder: "请输入退款原因", "auto-size": "" },
                model: {
                  value: _vm.returnPriceRemak,
                  callback: function($$v) {
                    _vm.returnPriceRemak = $$v
                  },
                  expression: "returnPriceRemak"
                }
              }),
              _c(
                "a-popconfirm",
                {
                  attrs: { title: "确认主动退款么?", okType: "danger" },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPrice(
                        _vm.orderReturnVo.id,
                        _vm.orderProductInfoVo.id
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("主动退款")
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "color-red ml-20 mt-5" }, [
                _vm._v("（一键退款，本功能无需买家退货，谨慎操作）")
              ])
            ],
            1
          )
        : _vm._e(),
      ![-10, -14].includes(_vm.orderProductInfoVo.refundStatus)
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "defalut" },
                  on: {
                    click: function($event) {
                      return _vm.handleCreateReturnPriceOrReturnProduct(
                        _vm.orderProductInfoVo
                      )
                    }
                  }
                },
                [_vm._v("发起退货退款申请")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-start-center flex-wrap mt-20" },
        [
          _c(
            "a-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleShowOrderPayPopup(_vm.orderProductInfoVo.id)
                }
              }
            },
            [_vm._v("订单赔付")]
          )
        ],
        1
      ),
      true
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c("a-textarea", {
                staticClass: "w-600 mr-20",
                attrs: {
                  placeholder: "请输入备注买卖双方都可见",
                  "auto-size": ""
                },
                model: {
                  value: _vm.remarkText,
                  callback: function($$v) {
                    _vm.remarkText = $$v
                  },
                  expression: "remarkText"
                }
              }),
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleAddNotes(_vm.orderProductInfoVo.id)
                    }
                  }
                },
                [_vm._v("备注")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("CreateReturnPriceOrReturnProductPopup", {
        ref: "createReturnPriceOrReturnProductPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("OrderPaidPopup", {
        ref: "orderPaidPopupEl",
        on: { paidOrderPrice: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }