<template>
  <a-modal v-model="showPopup" :width='500' title="修改退款理由">
    <template #footer>
      <div class="flex-sp-bt-center">
        <a-button @click="handleShowUserVoucher">查看双方举证/留言</a-button>
        <div class="flex-end-center">
          <a-button @click="handleCancel">取消</a-button>
          <a-button type="primary" @click="handleConfirm">{{ buttonText }}</a-button>
        </div>
      </div>
    </template>
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-item label="退款理由">
        <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.returnsType" placeholder="退款理由">
          <a-select-option v-for="(item, value) of returnPriceReasonList" :key="value" :value="value">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="承担运费">
        <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.isAssumeLogisticsFee" placeholder="是否承担运费">
          <a-select-option v-for="(item) of logisticsPriceStatusList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="补偿费用" v-if="false">
        <a-input allowClear v-model="params.price" type="number" class="w-200" placeholder='补偿额外费用'></a-input>
      </a-form-item>
      <a-form-item label="话术模版">
        <a-input
            allowClear
            v-model="params.remark"
            type="textarea"
            :auto-size="{ minRows: 4, maxRows: 8 }"
            class="w-100-w"
            placeholder='此话术模版为售后判定结果的话术模版，买卖双方均可见，前期先客服人员自己填写'
        ></a-input>
      </a-form-item>
    </a-form-model>
<!--  展示双方举证列表  -->
    <ReturnOrderUserVoucherListPopup ref="returnOrderUserVoucherListPopupEl"/>
  </a-modal>
</template>
<script>
import ReturnOrderUserVoucherListPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderUserVoucherListPopup/index.vue"
import _returnOrderListMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderListMixin"
import {postChangeReturnPriceReasonApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import {logisticsPriceStatusList} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_data"

export default {
  components: {ReturnOrderUserVoucherListPopup},
  emits: ['success'],
  mixins: [_returnOrderListMixin],
  data() {
    return {
      showPopup: false,
      returnPriceReasonList: [],
      logisticsPriceStatusList,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        returnId: undefined,
        returnsType: undefined, // 退款理由
        isAssumeLogisticsFee: undefined, // 是否承担运费,
        returnReason: undefined,  // 话术模版内容
        // price: undefined,  // 补偿费用
      },
      buttonText: '确定'
    }
  },
  methods: {
    async show(data) {
      this.$loading.show()
      await this.getReturnPriceReasonList()
      this.$loading.hide()
      this.params.returnId = data.orderId
      this.params.returnsType = data.returnsType
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.returnsType) return this.$message.warning('退款原因不能为空')
      if (!this.params.isAssumeLogisticsFee) return this.$message.warning('请选择承担运费')
      this.$loading.show()
      const res = await postChangeReturnPriceReasonApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        returnId: undefined,
        returnsType: undefined, // 退款理由
        isAssumeLogisticsFee: undefined, // 是否承担运费,
        returnReason: undefined,  // 话术模版内容
        // price: undefined,  // 补偿费用
      }
      this.showPopup = false
    },
    /** 查看买卖双方的举证 */
    handleShowUserVoucher() {
      this.$refs.returnOrderUserVoucherListPopupEl.show(this.params.returnId)
    }
  }
}
</script>