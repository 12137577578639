<template>
  <div>
    <div class="flex-colum-start-start flex-wrap mt-20">
      <div class="flex-start-center">
        订单逆向退款状态：
        <span class="color-red font-weight-bold" :class="orderReturnPriceStatusClassType[orderProductInfoVo.refundStatus]">
          {{orderProductInfoVo.refundStatusName}}
        </span>
        <span
            class="flex-start-center font-weight-bold ml-10"
            v-if="orderProductInfoVo.orderReturnRemainTimeLong && showCountTimeStatus"
        >
          （<BaseCountdownCom ref="baseCountdownComByReturnEl" :remaining-time="orderProductInfoVo.orderReturnRemainTimeLong"/>）
        </span>
      </div>
      <div class="mt-10">
        货物状态：
        <span class="color-black font-weight-bold" :class="orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].textColor : ''">
          {{ orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].goodsStatus : '-' }}
        </span>
      </div>
      <div class="mt-10">
        退款理由：
        <span class="color-red font-weight-bold">{{ orderReturnVo.returnsTypeValue }}</span>
        <a-button
            v-if="orderProductInfoVo.ratingCompanyId !== 1"
            class="ml-20"
            type="danger"
            size="small"
            @click="handleChangeReturnPriceReason(orderReturnVo.returnsType, orderReturnVo.returnsTypeValue)"
        >修改退款理由</a-button>
        <a-button class="ml-20" size="small" @click="handleChangeLogisticsPrice(orderReturnVo)">修改退货运费</a-button>
        <a-button v-if="orderReturnVo.isShowDelivery" class="ml-20" type="danger" size="small" @click="handleSelectSellerNotCanSendProduct(orderReturnVo)">无法交付</a-button>
      </div>
      <div class="mt-10">
        退款类型：
        <span class="color-red font-weight-bold">
          {{ orderReturnVo.receiptType ? receiptTypeMapText[orderReturnVo.receiptType].returnPriceType : '-' }}
        </span>
      </div>
      <div class="mt-10 flex-start-center" v-if="orderReturnVo.returnsFile">
        买家退款凭证：
        <div class="w-50 h-50 flex-center-center" v-for="(img, index) in orderReturnVo.returnsFile" :key="index">
          <img class="w-100-w h-100-h" :src="img" alt="" @click="handleShowBigImg(orderReturnVo.returnsFile, index)">
        </div>
        <a-button class="ml-" v-if="orderReturnVo.videoUrl" @click="handleGoToShowVideo(orderReturnVo.videoUrl)">查看视频</a-button>
      </div>
      <div class="mt-10" v-if="orderReturnVo.returnsReason">
        买家退款补充描述：
        <span class="color-black font-weight-bold">
          {{ orderReturnVo.returnsReason }}
        </span>
      </div>
      <div class="mt-10" v-if="orderReturnVo.returnsOfService">
        其他：
        <span class="color-black font-weight-bold">
          {{ orderReturnVo.returnsOfService }}
        </span>
      </div>
<!--   买家，卖家，赔付信息   -->
      <div class="mt-30 flex-start-start">
        <div class="flex-colum-start-start">
          <div class="font-weight-bold color-black">买家赔付信息</div>
          <div class="flex-start-center mt-10" v-for="(item, index) in buyerCompensationInformation" :key="index">
            <div class="flex-start-center font-weight-bold w-250">{{index}}</div>
            <div class="flex-start-center font-weight-bold color-red w-50">{{item}}</div>
          </div>
        </div>
        <div class="flex-colum-start-start ml-100">
          <div class="font-weight-bold color-black">卖家赔付信息</div>
          <div class="flex-start-center mt-10" v-for="(item, index) in sellerCompensationInformation" :key="index">
            <div class="flex-start-center font-weight-bold w-250">{{index}}</div>
            <div class="flex-start-center font-weight-bold color-red w-50">{{item}}</div>
          </div>
        </div>
      </div>
    </div>
<!--  修改退款理由  -->
    <ChangeReturnPriceReasonPopup ref="changeReturnPriceReasonPopupEl" @success="handleSuccess"/>
    <ChangeReturnLogisticsPricePopup ref="changeReturnLogisticsPricePopupEl" @success="handleSuccess"/>
    <SellerNotCanSendProductPopup ref="sellerNotCanSendProductPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import BaseCountdownCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseCountdownCom/index.vue"
import {orderReturnPriceStatusClassType} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import ChangeReturnPriceReasonPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderReturnPriceInfo/_components/ChangeReturnPriceReasonPopup/index.vue"
import _returnOrderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderDetailMixin"
import ChangeReturnLogisticsPricePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderReturnPriceInfo/_components/ChangeReturnLogisticsPricePopup/index.vue"
import SellerNotCanSendProductPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderReturnPriceInfo/_components/SellerNotCanSendProductPopup/index.vue"

export default {
  emits: ['success'],
  mixins: [_returnOrderDetailMixin],
  components: {
    BaseCountdownCom, ChangeReturnPriceReasonPopup, ChangeReturnLogisticsPricePopup,
    SellerNotCanSendProductPopup,
  },
  props: [
    'orderId', 'orderReturnVo', 'orderProductInfoVo', 'showCountTimeStatus',
    'buyerCompensationInformation', 'sellerCompensationInformation', 'sellerRejectConfirmTakeOverProductInfo',
    'sellerRejectReturnPriceInfo'
  ],
  data() {
    return {
      orderReturnPriceStatusClassType, // 退款状态
      receiptTypeMapText: {
        goodsreceived: {
          goodsStatus: '我已收到货',
          returnPriceType: '退货退款',
          textColor: 'color-green'
        },
        goodsnotreceived: {
          goodsStatus: '我未收到货',
          returnPriceType: '仅退款',
          textColor: 'color-red'
        },
        reject: {
          goodsStatus: '我已拒收',
          returnPriceType: '仅退款',
          textColor: 'color-red'
        }
      }
    }
  },
  methods: {
    /** 卖家无法交付 */
    handleSelectSellerNotCanSendProduct() {
      this.$refs.sellerNotCanSendProductPopupEl.show({returnId: this.orderId})
    },
    /** 修改运费信息 */
    handleChangeLogisticsPrice(returnVo) {
      this.$refs.changeReturnLogisticsPricePopupEl.show({
        returnId: this.orderId,
        logisticsFee: returnVo.logisticsFee,
        payOrderPrice: this.orderProductInfoVo.payMoney
      })
    },
    /** 查看视频 */
    handleGoToShowVideo(videoUrl) {
      window.open(videoUrl, '_blank')
    },
    /** 修改退款理由 */
    handleChangeReturnPriceReason(type, value) {
      this.$refs.changeReturnPriceReasonPopupEl.show({
        orderId: this.orderId,
        orderNo: this.orderProductInfoVo.orderNo,
        voucherOrderId: this.orderProductInfoVo.id,
        returnsType: type,
        returnsTypeValue: value,
        logisticsFee: this.orderReturnVo.logisticsFee,
        payOrderPrice: this.orderProductInfoVo.payMoney
      })
    },
    handleSuccess() {
      this.$emit('success')
    },
    handleClearTimerByReturn() {
      if (this.$refs.baseCountdownComByReturnEl) {
        this.$refs.baseCountdownComByReturnEl.clearTimer()
      }
    }
  }
}
</script>