<template>
  <div>
    <a-modal v-model="showPopup" :width='500' title="介入列表">
      <template #footer>
        <div class="flex-start-center">
          <a-button type="primary" @click="handleChangeJoinReason">修改介入原因</a-button>
        </div>
      </template>
      <div class="order-log-list-info-pages" v-if="joinList && joinList.length">
        <div class="order-log-item" v-for="(item,index) of joinList" :key="index">
          <div class="order-item-left">{{ index + 1 }}</div>
          <div class="order-item-right">
            <div class="font-weight-bold color-gray">{{item.createTime}}</div>
            <div class="font-weight-bold color-black mt-5">{{item.sysAccountName}}【<span class="color-blue">{{ item.sysAccountId }}</span>】</div>
            <div class="mt-5">
              <span>备注内容：</span>
              <span class="font-weight-bold color-black">{{ item.content }}</span>
            </div>
            <div class="mt-5 flex-start-center flex-wrap">
              <img
                  class="w-50 h-50"
                  v-for="(src, index) in item.pictureList"
                  :key="index" :src="src" alt=""
                  @click="handleShowBigImg(item.pictureList, index)"
              >
            </div>
          </div>
        </div>
      </div>
      <a-empty v-else />
    </a-modal>
<!--  增加介入原因  -->
    <ChangeJoinReasonPopup ref="changeJoinReasonPopupEl" @success="handleSuccess(orderId)"/>
  </div>
</template>
<script>
import {
  getServiceJoinReasonListApi,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import ChangeJoinReasonPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderJoinReasonPopup/_components/ChangeJoinReasonPopup/index.vue"
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"

export default {
  emits: ['success'],
  components: {ChangeJoinReasonPopup},
  mixins: [_showBigImgMixin],
  data() {
    return {
      joinList: [],
      showPopup: false,
      orderNo: undefined,
      orderId: undefined,
      returnId: undefined
    }
  },
  methods: {
    async show(orderId, orderNo, returnId) {
      this.returnId = returnId
      this.orderNo = orderNo
      this.orderId = orderId
      await this.getJoinReasonList(orderId)
    },
    /** 获取介入列表 */
    async getJoinReasonList(orderId) {
      this.$loading.show()
      const res = await getServiceJoinReasonListApi({orderId: orderId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.joinList = res.data
      this.showPopup = true
    },
    /** 增加举证页面 */
    handleChangeJoinReason() {
      this.$refs.changeJoinReasonPopupEl.show(this.returnId)
    },
    /** 成功 */
    handleSuccess(orderId) {
      this.getJoinReasonList(orderId)
      this.$emit('success')
    }
  }
}
</script>
<style scoped lang="scss">
.order-log-list-info-pages {
  width: 100%;

  .order-log-item {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .order-item-left {
      margin-right: 20px;
      width: 30px !important;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: gray;
    }

    .order-item-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
</style>