<template>
  <a-modal
      v-model="showPopup"
      :width='600'
      title="创建退货退款申请"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-item label="退货退款原因">
        <a-select
            v-model="params.refundType"
            :filter-option="untils.filterOption"
            placeholder='选择退货原因'
            @change="handleChangeRefundType"
        >
          <a-select-option
              v-for="(item, index) in createApplyReturnPriceOrReturnProductReasonList"
              :key="index"
              :value='index'
          >{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
<!--  是否需要继续交易    -->
      <a-form-item label="是否继续交易" v-if="params.refundType === 'physicaldiscrepancy'">
        <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.type" placeholder="是否继续交易">
          <a-select-option v-for="(item) of isContinueActionList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--  是否扣除保证金    -->
      <a-form-item :label="computedDepositTitle" v-if="!params.type">
        <a-radio-group name="radioGroup" :default-value="0" @change="handleChangeRadio">
          <a-radio :value="1">扣除</a-radio>
          <a-radio :value="0">不扣除</a-radio>
        </a-radio-group>
      </a-form-item>
<!--  是否扣除手续费    -->
      <a-form-item label="是否扣除手续费" v-if="!params.type">
        <a-radio-group name="radioGroup" :default-value="1" @change="handleChangeRadioByHandleFees">
          <a-radio :value="1">扣除</a-radio>
          <a-radio :value="0">不扣除</a-radio>
        </a-radio-group>
      </a-form-item>
<!--  修改退款类型    -->
      <a-form-item label="退款类型" v-if="!params.type">
        <a-radio-group name="radioGroup" :default-value="-1" @change="handleChangeRadioReturnAndRefundsType">
          <a-radio value="goodsnotreceived">仅退款</a-radio>
          <a-radio value="goodsreceived">退货退款</a-radio>
        </a-radio-group>
      </a-form-item>
<!--  物流运费    -->
      <a-form-item :label="computedLogisticsFeeTitle" v-if="!params.type">
        <a-input
            type="number"
            :placeholder='computedLogisticsFeeTitle'
            v-model="params.expressMoney"
            @change="handleChangeRefundType"
        ></a-input>
      </a-form-item>
<!--  评级费用    -->
      <a-form-item label="补偿买家评级费" v-if="['sham', 'productXiaci'].includes(params.refundType)">
        <a-input
            type="number"
            placeholder='补偿买家评级费'
            v-model="params.certificateMoney"
            @change="handleChangeRefundType"
        ></a-input>
      </a-form-item>
      <a-form-item label="补充说明">
        <a-input
            allowClear
            v-model="params.refundReason"
            type="textarea"
            :auto-size="{ minRows: 4, maxRows: 10 }"
            class="w-100-w"
            placeholder="补充说明"
        ></a-input>
      </a-form-item>
<!--  上传凭证    -->
      <a-form-item label="上传凭证">
        <a-upload
            :key="new Date().getTime()"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="picList"
            @preview="handlePreview()"
            @change="handleChange"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {
  postConfirmApplyReturnPriceOrReturnProductApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import {
  applyReturnPriceOrReturnProductReasonMapText,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_data"
import {mapState} from "vuex"
import _returnOrderListMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderListMixin"
import {applyReturnPriceReasonList, isContinueActionList} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"

export default {
  mixins: [_returnOrderListMixin],
  emits: ['success'],
  data() {
    return {
      untils,
      createApplyReturnPriceOrReturnProductReasonList: applyReturnPriceReasonList,  // 申请退货退款原因
      isContinueActionList,
      showPopup: false,
      layout: {
        labelCol: { span: 8 }, //标签占5份  共24份
        wrapperCol: { span: 16 },
      },
      picList: [],
      params: {
        orderId: undefined, // order.id;
        status: undefined, // order.status;
        refundType: undefined, // 退款原因
        type: undefined, // 是否继续交易
        ratingServicePrice: undefined, // 补偿买家评级费用
        certificateMoney: undefined, // 0; 其他费用（额外费用）
        expressMoney: undefined, // 0;
        isDeductDeposit: undefined, // 0
        isDeductService: undefined, // 1
        refundReason: undefined, // 补充说明
        formDataArray: undefined,  // 其他的文案相关
        refundImages: [], // 凭证图片
      },
      isDeductDeposit: 0, // 保证金（是否扣除）
      isDeductService: 1, // 手续费（是否扣除）
      returnAndRefundsType: -1, // 退款类型（退货退款，仅退款）
    }
  },
  methods: {
    async show(data) {
      this.params = data
      this.showPopup = true
    },
    /** 确定修改---前置判断 */
    async handleConfirm() {
      if (!this.params.refundType) return this.$message.warning('退款原因不能为空')
      if (this.isDeductDeposit === -1 && !this.params.type) return this.$message.warning('是否扣除保证金必须选择')
      if (this.isDeductService === -1 && !this.params.type) return this.$message.warning('是否扣除手续费必须选择')
      if (this.returnAndRefundsType === -1 && !this.params.type) return this.$message.warning('退款类型必须选择')
      this.params.refundImages = this.picList.map(item => item.url)
      this.params.isDeductDeposit = this.isDeductDeposit
      this.params.isDeductService = this.isDeductService
      this.params.returnAndRefundsType = this.returnAndRefundsType
      let postData = JSON.parse(JSON.stringify(this.params));
      postData.expressMoney = postData.expressMoney * 1
      await this.submitApplyReturnPriceOrReturnProduct(postData)
    },
    async submitApplyReturnPriceOrReturnProduct(postData) {
      const res = await postConfirmApplyReturnPriceOrReturnProductApi(postData)
      if (res.status !== '200') return
      this.$message.info(res.message || '成功');
      this.handleCancel();
      this.$emit('success')
    },
    /** 修改退款类型（仅退款，退货退款） */
    async handleChangeRadioReturnAndRefundsType(e) {
      this.returnAndRefundsType = e.target.value
    },
    /** 是否扣除手续费 */
    async handleChangeRadioByHandleFees(e) {
      this.isDeductService = e.target.value
    },
    /** 修改是否扣除卖买家保证金 */
    async handleChangeRadio(e) {
      this.isDeductDeposit = e.target.value
    },
    /** 确定修改退货退款原因， 获取最新的买卖双方费率 */
    async handleChangeRefundType() {

    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined, // order.id;
        status: undefined, // order.status;
        refundType: undefined, // 退款理由
        type: undefined,  // 是否需要继续交易
        ratingServicePrice: undefined, // 补偿买家评级费用
        certificateMoney: undefined, // 补偿买家评级费用
        expressMoney: undefined, // 0;
        isDeductDeposit: undefined, // 0
        isDeductService: undefined, // 1
        refundReason: undefined, // 补充说明
        formDataArray: undefined,  // 其他的文案相关
        refundImages: [], // 凭证图片
      }
      this.userRateData = ''
      this.picList = []
      this.isDeductDeposit = 0 // 保证金（是否扣除）
      this.isDeductService = 1 // 手续费（是否扣除）
      this.returnAndRefundsType = -1 // 退款类型（退货退款，仅退款）
      this.showPopup = false
    },
    /** 图片上传 */
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.picList = fileList;
    },
    /** 查看大图 */
    async handlePreview() {
      let urls = this.picList.map((el) => ({img_url: el.url}))
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
  computed: {
    ...mapState(['ip']),
    computedLogisticsFeeTitle() { // 物流费用变更标题
      const refundType = this.params.refundType
      return refundType ? applyReturnPriceOrReturnProductReasonMapText[refundType].logisticsText || '物流运费' : '物流运费'
    },
    computedDepositTitle() { // 计算显示标题是否扣除保证金
      const refundType = this.params.refundType;
      return refundType ? applyReturnPriceOrReturnProductReasonMapText[refundType].bindPriceText || '是否扣除保证金' : '是否扣除保证金'
    },
  }
}
</script>