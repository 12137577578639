<template>
  <a-modal v-model="showPopup" :width='500' title="修改介入状态" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout">
      <a-form-item label="介入状态修改">
        <a-select allowClear showSearch class="ml-5 w-300 mb-5" v-model="params.serviceJoinStatus" placeholder="介入状态修改">
          <a-select-option v-for="item of serviceJoinTypeList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="介入原因修改">
        <a-select allowClear showSearch class="ml-5 w-300 mb-5" v-model="params.serviceJoinReason" placeholder="介入原因修改">
          <a-select-option v-for="(name, value) of joinReasonList" :key="value" :value="value">{{ name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  getServiceInterventionReasonListApi,
  putServiceInterventionReasonApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import {serviceJoinTypeList} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_data"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      joinReasonList: {},
      layout: {
        labelCol: { span: 6 }, //标签占5份  共24份
        wrapperCol: { span: 18 },
      },
      params: {
        returnId: undefined,
        serviceJoinStatus: 2,
        serviceJoinReason: undefined
      },
      serviceJoinTypeList: serviceJoinTypeList,
    }
  },
  async mounted() {
    await this.handleJoinReasonList()
  },
  methods: {
    async show(returnId) {
      this.params.returnId = returnId
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (this.params.serviceJoinStatus === undefined) return this.$message.warning('请选择介入状态')
      this.$loading.show()
      const res = await putServiceInterventionReasonApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.handleCancel()
      this.$emit('success')
    },
    /** 获取介入原因列表 */
    async handleJoinReasonList() {
      this.$loading.show()
      const res = await getServiceInterventionReasonListApi()
      this.$loading.hide()
      if (res.status !== '200') return
      this.joinReasonList = res.data
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        returnId: undefined,
        serviceJoinStatus: undefined,
        serviceJoinReason: undefined
      }
      this.showPopup = false
    },
  }
}
</script>