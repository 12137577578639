<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   标记   -->
      <div slot="itemActionTagSlot" slot-scope="row">
        <a-popconfirm
            :title="row.markStatus ? '确定取消标记？' : '确定标记？'"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleConfirmActionOver(row.id, row.markStatus)"
        >
          <a-checkbox :checked='!!row.markStatus' :class="row.markStatus ? 'color-green' : 'color-black'">标记</a-checkbox>
        </a-popconfirm>
      </div>
<!--   订单编号   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div class="font-weight-bold">【{{ row.id }}】</div>
        <div class="font-weight-bold mt-10" :class="orderTypeClassType[row.orderKind]">{{ computedOrderTypeListMapText(row.orderKind) }}</div>
        <div class="mt-5 font-weight-bold">【<span class="color-blue cur-pot" @click="handleGoToOrderList(row)">{{ row.orderNo }}</span>】</div>
        <div class="mt-5" v-if="row.remarkNewest.id">客服备注：<span class="color-black font-weight-bold">{{ row.remarkNewest.content }}</span></div>
      </div>
<!--   产品名称   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div
            v-if="row.productReviewStatus"
            :class="orderProductLevelClassType[row.productReviewStatus]"
        >【{{ computedOrderProductLevelMapText(row.productReviewStatus) }}】</div>
        <div class="mt-10 font-weight-bold color-black cur-pot" @click="handleGoToProductList(row)">{{ row.productName }}</div>
      </div>
<!--   快照   -->
      <div slot="itemProductImgListSlot" slot-scope="row" class="flex-start-center flex-wrap">
        <div v-for="(item, index) in row.coverImages" :key="index" v-lazy-container="{ selector: 'img' }">
          <img
              @click="handleShowBigImg(row.coverImages, index)"
              class="w-50 h-50"
              :data-src="item"
          />
        </div>
      </div>
<!--   订单金额   -->
      <div slot="itemOrderPriceAndPayTypeSlot" slot-scope="row">
        <div class="font-weight-bold color-red">￥{{ row.returnsAmount }}</div>
      </div>
<!--   订单状态   -->
      <div slot="itemOrderStatusSlot" slot-scope="row">
        <div class="font-weight-bold">订单状态【<span :class="orderStatusTypeListClassType[row.orderStatus]">{{ computedOrderStatusTypeListMapText(row.orderStatus) || '未知' }}</span>】</div>
        <div v-if="false" class="mt-5 font-weight-bold">退单状态【<span :class="orderReturnPriceStatusClassType[row.refundStatus]">{{ row.refundStatusName || '暂无' }}</span>】</div>
        <div class="mt-5 font-weight-bold">退款状态【<span :class="orderReturnPriceStatusClassType[row.returnsReviewStatus]">{{ row.returnsReviewStatusName || '暂无' }}</span>】</div>
        <div class="mt-20"><a-button type="primary" @click="handleShowOrderDetail(row)">订单详情</a-button></div>
      </div>
<!--   卖家，买家信息   -->
      <div slot="itemBuyerInfoOrSellerInfoSlot" slot-scope="row">
        <div class="font-weight-bold">买家信息【<span class="color-green">{{ row.buyerUserName + '---' + row.buyerUserId }}</span>】</div>
        <div class="mt-5 font-weight-bold">卖家信息【<span class="color-blue">{{ row.sellerUserName + '---' + row.sellerUserId }}</span>】</div>
        <div class="mt-5" v-if="row.groupId">
          <a-button @click="handleShowUserChatList(row)">查看聊天记录</a-button>
        </div>
      </div>
<!--   客服介入   -->
      <div slot="itemServiceJoinStatusSlot" slot-scope="row">
        <div>
          <a-button @click="handleShowVoucherList(row)">查看举证/留言列表</a-button>
        </div>
        <div class="mt-20 font-weight-bold" :class="serviceJoinTypeClassType[row.isServiceInterveneDesc]">{{ computedServiceJoinTypeListMapText(row.isServiceInterveneDesc) }}</div>
        <div class="mt-5 font-weight-bold" v-if="row.serviceJoinReasonDesc">
          介入原因：<span class="color-red">{{ row.serviceJoinReasonDesc }}</span>
        </div>
        <div class="mt-5 color-gray" v-if="row.serviceJoinReasonDesc">
          介入时间：<span>{{ row.serviceJoinTime }}</span>
        </div>
        <div class="mt-5">
          <a-button type="primary" @click="handleShowEditorJoinReason(row)">编辑介入原因</a-button>
        </div>
        <div class="mt-5">
          <a-button @click="handleShowJoinReasonList(row)">查看介入列表</a-button>
        </div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row"></div>
    </a-table>
<!--  聊天记录  -->
    <UserChatListModel ref="userChatListModelEl"/>
<!--  查看凭证留言信息  -->
    <ReturnOrderUserVoucherListPopup ref="returnOrderUserVoucherListPopupEl"/>
<!--  订单详情  -->
    <ReturnOrderDetailPopup ref="returnOrderDetailPopupEl" @success="handleSuccess" />
<!--  查看介入原因  -->
    <ReturnOrderJoinReasonPopup ref="returnOrderJoinReasonPopupEl" @success="handleSuccess"/>
<!--  编辑介入原因  -->
    <ChangeJoinReasonPopup ref="changeJoinReasonPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {
  orderReturnTableColumns, serviceJoinTypeClassType,
  serviceJoinTypeListMapText
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_data"
import {
  orderProductLevelClassType,
  orderProductLevelMapText,
  orderReturnPriceStatusClassType,
  orderStatusTypeListClassType,
  orderStatusTypeListMapText,
  orderTypeClassType,
  orderTypeListMapText,
  rateServiceTypeStatusClassType,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import UserChatListModel from "@/views/cmsPage/dqPlatform/components/UserChatListModel.vue"
import ReturnOrderUserVoucherListPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderUserVoucherListPopup/index.vue"
import ReturnOrderDetailPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/index.vue"
import {goToOtherPage} from "@/untils/otherEvent"
import {postServiceTagActionOverApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import ReturnOrderJoinReasonPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderJoinReasonPopup/index.vue"
import ChangeJoinReasonPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderJoinReasonPopup/_components/ChangeJoinReasonPopup/index.vue"

export default {
  components: {
    UserChatListModel, ReturnOrderUserVoucherListPopup, ReturnOrderDetailPopup,
    ReturnOrderJoinReasonPopup, ChangeJoinReasonPopup
  },
  props: ['pagination', 'list'],
  data() {
    return {
      serviceJoinTypeClassType, // 客服介入
      orderProductLevelClassType, // 产品等级，
      orderTypeClassType, // 订单类型样式
      orderStatusTypeListClassType, // 订单状态
      rateServiceTypeStatusClassType, // 评级状态
      orderReturnPriceStatusClassType, // 退款状态
      tableList: orderReturnTableColumns,
    }
  },
  computed: {
    computedOrderProductLevelMapText() { // 等级
      return (value) => orderProductLevelMapText(value)
    },
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
    computedOrderStatusTypeListMapText() { // 订单状态
      return (value) => orderStatusTypeListMapText(value)
    },
    computedServiceJoinTypeListMapText() { // 客服介入
      return (value) => serviceJoinTypeListMapText(value)
    },
  },
  methods: {
    /** 编辑介入原因 */
    handleShowEditorJoinReason(row) {
      this.$refs.changeJoinReasonPopupEl.show(row.id)
    },
    /** 查看介入列表 */
    handleShowJoinReasonList(row) {
      this.$refs.returnOrderJoinReasonPopupEl.show(row.orderId, row.orderNo, row.id)
    },
    /** 标记已处理 */
    async handleConfirmActionOver(id, status) {
      const res = await postServiceTagActionOverApi(id, status ?  0 : 1)
      if (res.status !== '200') return
      this.$message.info('标记成功')
      this.handleSuccess()
    },
    /** 去往订单列表 */
    handleGoToOrderList(row) {
      goToOtherPage(`/cms/dqplatform/newordermannage?orderNo=${row.orderNo}`)
    },
    /** 去往拍品列表 */
    handleGoToProductList(row) {
      let url
      if (row.orderKind === 1) {
        url = `/cms/currencymannage/goods?productName=${row.productId}`
      } else {
        url = `/cms/currencymannage/auctiongoods?productName=${row.productId}`
      }
      goToOtherPage(url)
    },
    /** 订单详情 */
    handleShowOrderDetail(row) {
      this.$refs.returnOrderDetailPopupEl.show(row)
    },
    /** 查看用户的举证列表 */
    handleShowVoucherList(row) {
      this.$refs.returnOrderUserVoucherListPopupEl.show(row.orderId, row.orderNo)
    },
    /** 查看聊天记录 */
    handleShowUserChatList(row) {
      this.$refs.userChatListModelEl.show(row.groupId, row.sellerUserId, row.buyerUserId)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 查看大图 */
    handleShowBigImg(urls, index) {
      const tempUrls = urls.map(el => ({img_url: el}))
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: tempUrls,
          current: index,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>