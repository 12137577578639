import {getLocalEnv} from "@/untils/otherEvent"

export default {
    data() {
        return {

        }
    },
    methods: {
        /** 远哥可以调整页面的每页数据大小 */
        async changeOrderListApiPageSizeByZJY() {
            const evn = getLocalEnv()
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            if (['local', 'dev'].includes(evn)) {
                this.pagination.showSizeChanger = true
            } else if ([4].includes(userInfo.id)) {
                this.pagination.showSizeChanger = true
            }
        },
        /** 改变选择是否发货即将超时 */
        async handleChangeShowSendProductTimeoutStatus(e) {
            this.isSendProductTimeoutStatus = e.target.checked
            if (this.isSendProductTimeoutStatus) {
                this.params.sendStatus = 1
            } else {
                this.params.sendStatus = 0
            }
            await this.getList()
        },
        /** 改变订单创建时间 */
        handleChangeOrderCreateTime(array) {
            if (array && array.length > 0) {
                array.forEach((ele, index) => {
                    if (index === 0) {
                        this.params.createStartTime = ele._d
                            .toLocaleDateString()
                            .replace(/\//g, "-");
                    } else {
                        this.params.createEndTime = ele._d
                            .toLocaleDateString()
                            .replace(/\//g, "-");
                    }
                });
            } else {
                this.params.createStartTime = undefined;
                this.params.createEndTime = undefined;
            }
        },
        /** 选择支付时间范围 */
        handleChangePayOverTime(array) {
            if (array && array.length > 0) {
                array.forEach((ele, index) => {
                    if (index === 0) {
                        this.params.payStartTime = ele._d
                            .toLocaleDateString()
                            .replace(/\//g, "-");
                    } else {
                        this.params.payEndTime = ele._d
                            .toLocaleDateString()
                            .replace(/\//g, "-");
                    }
                });
            } else {
                this.params.payStartTime = undefined;
                this.params.payEndTime = undefined;
            }
        }
    }
}