<template>
  <div>
    <a-drawer title="订单详情" placement="right" width="1400" :visible="shopPopup" @close="handleClosePopup">
<!--   基本信息   -->
      <BaseItemCom :title-text="`基本信息（${computedOrderTypeListMapText(orderType)}）`">
        <template #itemInfo>
          <ReturnOrderBaseInfo
              ref="orderBaseInfoEl"
              :product-snapshot="productSnapshot"
              :order-product-info-vo="orderProductInfoVo"
          />
        </template>
      </BaseItemCom>
<!--   支付信息   -->
      <BaseItemCom title-text="支付信息">
        <template #itemInfo>
          <ReturnOrderPayInfo :order-product-info-vo="orderProductInfoVo"/>
        </template>
      </BaseItemCom>
<!--    物流信息--正向  -->
      <BaseItemCom title-text="物流信息（正向物流）" v-if="orderLogisticsVo && orderLogisticsVo.id">
        <template #itemInfo>
          <OrderLogisticsInfo :order-logistics-vo="orderLogisticsVo"/>
        </template>
      </BaseItemCom>
<!--   物流信息--送评   -->
      <BaseItemCom title-text="物流信息（送评物流）" v-if="gradeLogisticsVo && gradeLogisticsVo.id">
        <template #itemInfo>
          <OrderLogisticsInfo :order-logistics-vo="gradeLogisticsVo" :orderReturnVo="orderReturnVo"/>
        </template>
      </BaseItemCom>
<!--   物流信息--逆向   -->
      <BaseItemCom title-text="物流信息（逆向物流）" v-if="orderReturnVo && orderReturnVo.consigneeAddress">
        <template #itemInfo>
          <OrderLogisticsInfo :order-logistics-vo="orderReturnVo"/>
        </template>
        <template #itemBtn>
          <a-button
              type="danger"
              size="small"
              @click="handleChangeAddressOrLogistics(orderReturnVo, orderReturnVo.id, 'returnOrder')"
          >修改退货物流</a-button>
        </template>
      </BaseItemCom>
<!--   退款信息   -->
      <BaseItemCom title-text="退款信息">
        <template #itemInfo>
          <ReturnOrderReturnPriceInfo
              :orderId="orderId"
              :order-return-vo="orderReturnVo"
              :orderProductInfoVo="orderProductInfoVo"
              :buyerCompensationInformation="buyerCompensationInformation"
              :sellerCompensationInformation="sellerCompensationInformation"
              @success="handleSuccess"
          />
        </template>
      </BaseItemCom>
<!--  售后操作，留言    -->
      <BaseItemCom title-text="售后操作/留言" v-if="[1, 2, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)">
        <template #itemInfo>
          <ReturnOrderActionInfo
              :order-return-vo="orderReturnVo"
              :orderProductInfoVo="orderProductInfoVo"
              @success="handleSuccess"
          />
        </template>
      </BaseItemCom>
<!--  日志备注   -->
      <div class="flex-sp-bt-start">
        <BaseItemCom title-text="订单日志">
          <template #itemInfo>
            <OrderLogListInfo :orderLogVo="orderLogVo" />
          </template>
        </BaseItemCom>
        <BaseItemCom title-text="订单退款日志">
          <template #itemInfo>
            <OrderLogListInfo :orderLogVo="returnsLogVo" />
          </template>
        </BaseItemCom>
        <BaseItemCom title-text="补充备注（仅内部客服人员可见，不对外展示）">
          <template #itemInfo>
            <OrderNoteListInfo :orderNoteList="orderNoteList" />
          </template>
          <template #itemBtn>
            <a-button @click="handleAddCenterNotes(orderReturnVo.id)">新增备注</a-button>
          </template>
        </BaseItemCom>
      </div>
    </a-drawer>
<!--  修改物流  -->
    <ChangeLogisticsMessage ref="changeLogisticsMessageEl" @change="handleSuccess" />
<!--  添加内部备注  -->
    <AddNotePopup ref="addNotePopupEl" @success="getServiceNoteList" />
  </div>
</template>
<script>
import BaseItemCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseItemCom/index.vue"
import {orderTypeListMapText} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import ChangeLogisticsMessage from "@/views/cmsPage/dqPlatform/components/ChangeLogisticsMessage.vue"
import AddNotePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/_components/AddNotePopup/index.vue"
import _returnOrderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderDetailMixin"
import ReturnOrderBaseInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderBaseInfo/index.vue"
import ReturnOrderPayInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderPayInfo/index.vue"
import OrderLogisticsInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderLogisticsInfo/index.vue"
import OrderNoteListInfo from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/index.vue"
import OrderLogListInfo from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderLogListInfo/index.vue"
import ReturnOrderReturnPriceInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderReturnPriceInfo/index.vue"
import ReturnOrderActionInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderActionInfo/index.vue"

export default {
  components: {
    OrderLogListInfo, OrderNoteListInfo, ReturnOrderActionInfo,
    OrderLogisticsInfo, ReturnOrderReturnPriceInfo,
    ReturnOrderPayInfo,
    BaseItemCom, ReturnOrderBaseInfo, ChangeLogisticsMessage, AddNotePopup
  },
  mixins: [_returnOrderDetailMixin],
  emits: ['success'],
  data() {
    return {
      shopPopup: false,
      orderType: undefined,
      orderId: undefined, // 订单编号
      orderProductInfoVo: '', // 订单产品基本信息
      productSnapshot: '', // 商品信息相关
      orderLogisticsVo: '', // 发货物流
      orderReturnVo: '', // 退款信息
      gradeLogisticsVo: '', // 送评物流
      orderLogVo: '', // 订单日志列表
      returnsLogVo: '', // 订单退款日志列表
      orderNoteList: '', // 订单日志列表
      buyerCompensationInformation: '', // 买家赔付信息
      sellerCompensationInformation: '', // 卖家赔付信息
    }
  },
  computed: {
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
  },
  methods: {
    /** 新增内部备注 */
    handleAddCenterNotes(orderId) {
      this.$refs.addNotePopupEl.show(orderId)
    },
    /** 修改物流相关 */
    handleChangeAddressOrLogistics(data, orderId, type) {
      this.$refs.changeLogisticsMessageEl.show(data, orderId, type)
    },
    /** 展示弹窗 */
    async show(data) {
      this.orderType = data.orderKind
      this.orderId = data.id
      await this.getReturnOrderDetailInfo()
      await this.getServiceNoteList()
    },
    /** 关闭弹窗 */
    handleClosePopup() {
      this.shopPopup = false
      if (this.orderProductInfoVo.orderRemainTimeLong) {
        // 关闭的时候清空计时器
        this.$refs.orderBaseInfoEl.handleClearTimer()
      }
      if (this.orderProductInfoVo.orderReturnRemainTime) {
        // 关闭的时候清空计时器
        this.$refs.orderBaseInfoEl.handleClearTimerByReturn()
      }
    },
    async handleSuccess() {
      await this.getReturnOrderDetailInfo()
      this.$forceUpdate()
      this.$emit('success')
    }
  }
}
</script>