<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单号相关   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div
            class="font-weight-bold" :class="orderTypeClassType[row.orderKind]">{{ computedOrderTypeListMapText(row.orderKind) }}</div>
        <div class="mt-5 font-weight-bold">
          【<span class="color-blue">{{ row.orderNo }}</span>】
        </div>
      </div>
<!--   产品名称   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div
            v-if="row.productReviewStatus"
            :class="orderProductLevelClassType[row.productReviewStatus]"
        >【{{ computedOrderProductLevelMapText(row.productReviewStatus) }}】</div>
        <div class="mt-10 font-weight-bold color-black cur-pot" @click="handleGoToProductList(row)">{{ row.productName }}</div>
      </div>
<!--   产品快照   -->
      <div slot="itemProductImgListSlot" slot-scope="row" class="flex-start-center flex-wrap">
        <div v-for="(item, index) in row.snapShootPicList" :key="index" v-lazy-container="{ selector: 'img' }">
          <img
              @click="handleShowBigImg(row.snapShootPicList, index)"
              class="w-50 h-50"
              :data-src="item"
          />
        </div>
      </div>
<!--  订单金额    -->
      <div slot="itemOrderPriceAndPayTypeSlot" slot-scope="row">
        <div class="font-weight-bold color-red">￥{{ row.orderPrice }}</div>
        <div class="mt-5 font-weight-bold" v-if="row.payType">【<span :class="payTypeListClassType[row.payType]">{{ computedPayTypeListMapText(row.payType) }}</span>】</div>
      </div>
<!--  订单状态    -->
      <div slot="itemOrderStatusSlot" slot-scope="row">
        <div class="font-weight-bold">订单状态【<span :class="orderStatusTypeListClassType[row.status]">{{ computedOrderStatusTypeListMapText(row.status) || '未知' }}</span>】</div>
        <div class="mt-5 font-weight-bold">评级类型【<span :class="rateServiceTypeStatusClassType[row.serviceType]">{{ computedRateServiceTypeStatusListMapText(row.serviceType) || '其他' }}</span>】</div>
        <div class="mt-5 font-weight-bold">退款状态【<span :class="orderReturnPriceStatusClassType[row.refundStatus]">{{ computedOrderReturnPriceStatusMapText(row.refundStatus) || '暂无' }}</span>】</div>
        <div class="mt-20" v-if="computedUserShowSynchronousOrder(row)">
          <a-popconfirm
              title="确定要同步当前订单？？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleSyncUserPayOrderStatus(row)"
          ><a-button size="small">同步支付订单</a-button></a-popconfirm>
        </div>
        <div class="mt-10" v-if="row.status === 0">
          <a-button size="small" type="danger" @click="handleSyncUserOfflinePayOrderStatus(row)">线下支付同步</a-button>
        </div>
        <div class="mt-20"><a-button type="primary" @click="handleShowOrderDetail(row)">订单详情</a-button></div>
      </div>
<!--  买家，卖家信息    -->
      <div slot="itemBuyerInfoOrSellerInfoSlot" slot-scope="row">
        <div class="font-weight-bold">买家信息【<span class="color-green">{{ row.buyerUserName + '---' + row.buyerUserId }}</span>】</div>
        <div class="mt-5 font-weight-bold">卖家信息【<span class="color-blue">{{ row.sellerUserName + '---' + row.sellerUserId }}</span>】</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">

      </div>
    </a-table>
<!--  详情弹窗  -->
    <OrderDetailPopup ref="orderDetailPopupEl" @success="handleSuccess" />
  </div>
</template>
<script>
import {
  orderTableColumns
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_data"
import {
  orderProductLevelClassType,
  orderProductLevelMapText, orderReturnPriceStatusClassType,
  orderReturnPriceStatusMapText,
  orderStatusTypeListClassType,
  orderStatusTypeListMapText,
  orderTypeClassType,
  orderTypeListMapText,
  rateServiceTypeStatusClassType,
  rateServiceTypeStatusListMapText,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import {payTypeListClassType, payTypeListMapType} from "@/views/cmsPage/dqPlatform/_data"
import OrderDetailPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/index.vue"
import {
  postSyncUserOfflinePayOrderStatusApi,
  postSyncUserPayOrderStatusApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import {getLocalEnv, goToOtherPage} from "@/untils/otherEvent"

export default {
  components: {OrderDetailPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      payTypeListClassType, // 支付方式
      orderProductLevelClassType, // 等级样式
      orderTypeClassType, // 订单类型样式类
      orderStatusTypeListClassType, // 订单状态
      rateServiceTypeStatusClassType, // 评级状态
      orderReturnPriceStatusClassType, // 退款状态
      tableList: orderTableColumns,
    }
  },
  computed: {
    computedUserShowSynchronousOrder() {
      const evn = getLocalEnv()
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      return row =>  {
        if (row.status === 0) {
          if (['local', 'dev'].includes(evn)) {
            return true
          } else return evn === 'production' && [11, 39, 20].includes(userInfo.id);
        } else {
          return false
        }
      }
    },
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
    computedPayTypeListMapText() { // 支付类型
      return (value) => payTypeListMapType(value)
    },
    computedOrderStatusTypeListMapText() { // 订单状态
      return (value) => orderStatusTypeListMapText(value)
    },
    computedRateServiceTypeStatusListMapText() { // 评级状态
      return (value) => rateServiceTypeStatusListMapText(value)
    },
    computedOrderReturnPriceStatusMapText() { // 退款状态
      return (value) => orderReturnPriceStatusMapText(value)
    },
    computedOrderProductLevelMapText() { // 等级
      return (value) => orderProductLevelMapText(value)
    },
  },
  methods: {
    /** 去往拍品列表 */
    handleGoToProductList(row) {
      let url
      if (row.orderKind === 1) {
        url = `/cms/currencymannage/goods?productName=${row.productId}`
      } else {
        url = `/cms/currencymannage/auctiongoods?productName=${row.productId}`
      }
      goToOtherPage(url)
    },
    /** 同步线下支付订单 */
    async handleSyncUserOfflinePayOrderStatus(row) {
      this.$confirm({
        title: '提示！',
        content: '次功能只支持直发订单同步,确认好页面显示的支付金额,金额不对联系技术,评级单不支持要联系技术处理【谨慎操作，联系技术操作！】',
        onOk: async () => {
          const res = await postSyncUserOfflinePayOrderStatusApi({orderNo: row.orderNo})
          if (res.status !== '200') return
          this.$message.info(res.message || '同步成功')
          this.handleSuccess()
        },
        onCancel() {},
      });
    },
    /** 同步支付订单 */
    async handleSyncUserPayOrderStatus(row) {
      const res = await postSyncUserPayOrderStatusApi({orderNo: row.orderNo})
      if (res.status !== '200') return
      this.$message.info(res.message || '同步成功')
      this.handleSuccess()
    },
    /** 查看订单详情页 */
    handleShowOrderDetail(row) {
      this.$refs.orderDetailPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 查看大图 */
    handleShowBigImg(urls, index) {
      const tempUrls = urls.map(el => ({img_url: el}))
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: tempUrls,
          current: index,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>