var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "订单详情",
            placement: "right",
            width: "1400",
            visible: _vm.shopPopup
          },
          on: { close: _vm.handleClosePopup }
        },
        [
          _c("BaseItemCom", {
            attrs: {
              "title-text":
                "基本信息（" +
                _vm.computedOrderTypeListMapText(_vm.orderType) +
                "）"
            },
            scopedSlots: _vm._u([
              {
                key: "itemInfo",
                fn: function() {
                  return [
                    _c("ReturnOrderBaseInfo", {
                      ref: "orderBaseInfoEl",
                      attrs: {
                        "product-snapshot": _vm.productSnapshot,
                        "order-product-info-vo": _vm.orderProductInfoVo
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("BaseItemCom", {
            attrs: { "title-text": "支付信息" },
            scopedSlots: _vm._u([
              {
                key: "itemInfo",
                fn: function() {
                  return [
                    _c("ReturnOrderPayInfo", {
                      attrs: { "order-product-info-vo": _vm.orderProductInfoVo }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm.orderLogisticsVo && _vm.orderLogisticsVo.id
            ? _c("BaseItemCom", {
                attrs: { "title-text": "物流信息（正向物流）" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "itemInfo",
                      fn: function() {
                        return [
                          _c("OrderLogisticsInfo", {
                            attrs: {
                              "order-logistics-vo": _vm.orderLogisticsVo
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  590373356
                )
              })
            : _vm._e(),
          _vm.gradeLogisticsVo && _vm.gradeLogisticsVo.id
            ? _c("BaseItemCom", {
                attrs: { "title-text": "物流信息（送评物流）" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "itemInfo",
                      fn: function() {
                        return [
                          _c("OrderLogisticsInfo", {
                            attrs: {
                              "order-logistics-vo": _vm.gradeLogisticsVo,
                              orderReturnVo: _vm.orderReturnVo
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3228502785
                )
              })
            : _vm._e(),
          _vm.orderReturnVo && _vm.orderReturnVo.consigneeAddress
            ? _c("BaseItemCom", {
                attrs: { "title-text": "物流信息（逆向物流）" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "itemInfo",
                      fn: function() {
                        return [
                          _c("OrderLogisticsInfo", {
                            attrs: { "order-logistics-vo": _vm.orderReturnVo }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "itemBtn",
                      fn: function() {
                        return [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeAddressOrLogistics(
                                    _vm.orderReturnVo,
                                    _vm.orderReturnVo.id,
                                    "returnOrder"
                                  )
                                }
                              }
                            },
                            [_vm._v("修改退货物流")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3575547978
                )
              })
            : _vm._e(),
          _c("BaseItemCom", {
            attrs: { "title-text": "退款信息" },
            scopedSlots: _vm._u([
              {
                key: "itemInfo",
                fn: function() {
                  return [
                    _c("ReturnOrderReturnPriceInfo", {
                      attrs: {
                        orderId: _vm.orderId,
                        "order-return-vo": _vm.orderReturnVo,
                        orderProductInfoVo: _vm.orderProductInfoVo,
                        buyerCompensationInformation:
                          _vm.buyerCompensationInformation,
                        sellerCompensationInformation:
                          _vm.sellerCompensationInformation
                      },
                      on: { success: _vm.handleSuccess }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          [1, 2, 3, 4, 5].includes(_vm.orderReturnVo.returnsReviewStatus)
            ? _c("BaseItemCom", {
                attrs: { "title-text": "售后操作/留言" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "itemInfo",
                      fn: function() {
                        return [
                          _c("ReturnOrderActionInfo", {
                            attrs: {
                              "order-return-vo": _vm.orderReturnVo,
                              orderProductInfoVo: _vm.orderProductInfoVo
                            },
                            on: { success: _vm.handleSuccess }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1312849660
                )
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-sp-bt-start" },
            [
              _c("BaseItemCom", {
                attrs: { "title-text": "订单日志" },
                scopedSlots: _vm._u([
                  {
                    key: "itemInfo",
                    fn: function() {
                      return [
                        _c("OrderLogListInfo", {
                          attrs: { orderLogVo: _vm.orderLogVo }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("BaseItemCom", {
                attrs: { "title-text": "订单退款日志" },
                scopedSlots: _vm._u([
                  {
                    key: "itemInfo",
                    fn: function() {
                      return [
                        _c("OrderLogListInfo", {
                          attrs: { orderLogVo: _vm.returnsLogVo }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("BaseItemCom", {
                attrs: {
                  "title-text": "补充备注（仅内部客服人员可见，不对外展示）"
                },
                scopedSlots: _vm._u([
                  {
                    key: "itemInfo",
                    fn: function() {
                      return [
                        _c("OrderNoteListInfo", {
                          attrs: { orderNoteList: _vm.orderNoteList }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "itemBtn",
                    fn: function() {
                      return [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.handleAddCenterNotes(
                                  _vm.orderReturnVo.id
                                )
                              }
                            }
                          },
                          [_vm._v("新增备注")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ChangeLogisticsMessage", {
        ref: "changeLogisticsMessageEl",
        on: { change: _vm.handleSuccess }
      }),
      _c("AddNotePopup", {
        ref: "addNotePopupEl",
        on: { success: _vm.getServiceNoteList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }