import {getReturnReasonListApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"

export default {
    data() {
        return {}
    },
    methods: {
        /** 获取退款理由列表 */
        async getReturnPriceReasonList() {
            const res = await getReturnReasonListApi()
            this.$loading.hide()
            if (res.status !== '200') return
            this.returnPriceReasonList = res.data
        }
    }
}
