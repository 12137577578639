<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20" v-if="orderProductInfoVo.refundStatus == null || orderProductInfoVo.refundStatus === -12">
      <a-tag
          v-for="(tag, index) in tagDayList"
          :key="index"
          class="cur-pot-not-line mr-20"
          :color="tag.selected ? 'blue' : ''"
          @click="handleChangeTag(tag.id)"
      >{{ tag.name }}</a-tag>
      <a-popconfirm title="确认延长收货么?" @confirm="handleConfirmExtendTakeOver(orderProductInfoVo.id)">
        <a-button type='danger'>确认延长收货</a-button>
      </a-popconfirm>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="[1, 2, 3, 4].includes(orderProductInfoVo.status)">
      <a-textarea v-model="returnPriceRemak" class="w-400 mr-20" placeholder="请输入退款原因" auto-size/>
      <a-popconfirm
          title="确认主动退款么?"
          okType="danger"
          @confirm="handleConfirmReturnPrice(orderReturnVo.id, orderProductInfoVo.id)"
      >
        <a-button type='danger'>主动退款</a-button>
      </a-popconfirm>
      <div class="color-red ml-20 mt-5">（一键退款，本功能无需买家退货，谨慎操作）</div>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="![-10, -14].includes(orderProductInfoVo.refundStatus)">
      <a-button type='defalut' @click="handleCreateReturnPriceOrReturnProduct(orderProductInfoVo)">发起退货退款申请</a-button>
    </div>
    <div class="flex-start-center flex-wrap mt-20">
      <a-button @click="handleShowOrderPayPopup(orderProductInfoVo.id)">订单赔付</a-button>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="true">
      <a-textarea v-model="remarkText" class="w-600 mr-20" placeholder="请输入备注买卖双方都可见" auto-size/>
      <a-button type='primary' @click="handleAddNotes(orderProductInfoVo.id)">备注</a-button>
    </div>
<!--  发起退货退款申请  -->
    <CreateReturnPriceOrReturnProductPopup ref="createReturnPriceOrReturnProductPopupEl" @success="handleSuccess"/>
<!--  订单赔付  -->
    <OrderPaidPopup ref="orderPaidPopupEl" @paidOrderPrice="handleSuccess"/>
  </div>
</template>
<script>
import {tagDayList} from "@/views/cmsPage/dqPlatform/_data"
import {
  postAddNotesApi,
  postConfirmReturnPriceByOneKeyApi,
  postExtendTakeOverProductApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import CreateReturnPriceOrReturnProductPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderActionInfo/_components/CreateReturnPriceOrReturnProductPopup/index.vue"
import OrderPaidPopup from "@/views/cmsPage/dqPlatform/components/OrderPaidPopup/index.vue"

export default {
  components: {OrderPaidPopup, CreateReturnPriceOrReturnProductPopup},
  props: ['orderProductInfoVo', 'orderReturnVo'],
  emits: ['success'],
  data() {
    return {
      remarkText: undefined, // 备注
      returnPayLoading: false,
      tagDayList: JSON.parse(JSON.stringify(tagDayList)),
      returnPriceRemak: undefined, // 退款原因
    }
  },
  methods: {
    /** 订单赔付 */
    handleShowOrderPayPopup(id) {
      this.$refs.orderPaidPopupEl.show(id)
    },
    /** 发起退货退款 */
    handleCreateReturnPriceOrReturnProduct(data) {
      const tempParams = {
        orderId: data.id,
        status: data.status,
        certificateMoney: 0,
        expressMoney: 0,
        isDeductDeposit: 0,
        isDeductService: 1,
      }
      tempParams.orderId = data.id;
      tempParams.status = data.status;
      tempParams.certificateMoney = 0;
      tempParams.expressMoney = 0;
      tempParams.isDeductDeposit = 0
      tempParams.isDeductService = 1
      this.$refs.createReturnPriceOrReturnProductPopupEl.show(tempParams)
    },
    /** 添加备注 */
    async handleAddNotes(orderId) {
      const res = await postAddNotesApi({orderId, content: this.remarkText})
      if (res.status !== '200') return
      this.$message.success(res.message || '成功');
      this.remarkText = undefined
      this.handleSuccess()
    },
    /** 确定一键退款 */
    async handleConfirmReturnPrice(orderReturnId, orderId) {
      if (this.returnPayLoading) return this.$message.warning("请勿重复提交");
      this.returnPayLoading = true
      if (!orderReturnId && !orderId) return this.$message.warning("orderId/orderReturnId不能为空");
      this.returnPayLoading = false
      this.$loading.show()
      const res = await postConfirmReturnPriceByOneKeyApi({orderReturnId, orderId, remark: this.returnPriceRemak})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || '成功');
      this.returnPriceRemak = undefined
      this.returnPayLoading = false
      this.handleSuccess()
    },
    /** 确定延长收货 */
    async handleConfirmExtendTakeOver(orderId) {
      if (!orderId) return this.$message.warning("orderId不存在");
      const found = this.tagDayList.find(el => el.selected)
      if (!found) return this.$message.warning('请先选择延长天数')
      const res = await postExtendTakeOverProductApi({daySize: found.id, orderId})
      if (res.status !== '200') return
      this.$message.success(res.message || '成功');
      this.handleSuccess()
      this.tagDayList = JSON.parse(JSON.stringify(tagDayList))
    },
    /** 切换选择延长天数 */
    handleChangeTag(id) {
      this.tagDayList.forEach(el => {
        el.selected = id === el.id;
      })
      this.$forceUpdate()
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-tag {
  padding: 6px !important;
  font-size: 14px !important;
}
</style>