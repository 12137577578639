<template>
  <a-modal
      v-model="showPopup"
      :width='500'
      title="修改标版费"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-item label="评级标版费">
        <a-input
            allowClear
            v-model="params.price"
            type="number"
            class="w-200"
            placeholder='评级标版费'
        ></a-input>
      </a-form-item>
      <a-form-item label="添加备注">
        <a-input
            allowClear
            v-model="params.remark"
            type="textarea"
            autoSize
            class="w-100-w"
            placeholder='此备注信息会展示在评级鉴定结果的页面，前端也会展示在订单历史记录里'
        ></a-input>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {postChangeRateServiceVersionPriceApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      params: {
        orderId: undefined,
        price: undefined,
        remark: undefined,
      }
    }
  },
  methods: {
    show(orderId) {
      this.params.orderId = orderId
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.price) return this.$message.warning('请输入修改的金额')
      if (!this.params.remark) return this.$message.warning('备注不能为空')
      this.$loading.show()
      const res = await postChangeRateServiceVersionPriceApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        price: undefined,
        remark: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>