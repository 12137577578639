var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex-colum-start-start flex-wrap mt-20" }, [
        _c("div", { staticClass: "flex-start-center" }, [
          _vm._v(" 订单逆向退款状态： "),
          _c(
            "span",
            {
              staticClass: "color-red font-weight-bold",
              class:
                _vm.orderReturnPriceStatusClassType[
                  _vm.orderProductInfoVo.refundStatus
                ]
            },
            [
              _vm._v(
                " " + _vm._s(_vm.orderProductInfoVo.refundStatusName) + " "
              )
            ]
          ),
          _vm.orderProductInfoVo.orderReturnRemainTimeLong &&
          _vm.showCountTimeStatus
            ? _c(
                "span",
                { staticClass: "flex-start-center font-weight-bold ml-10" },
                [
                  _vm._v(" （"),
                  _c("BaseCountdownCom", {
                    ref: "baseCountdownComByReturnEl",
                    attrs: {
                      "remaining-time":
                        _vm.orderProductInfoVo.orderReturnRemainTimeLong
                    }
                  }),
                  _vm._v("） ")
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "mt-10" }, [
          _vm._v(" 货物状态： "),
          _c(
            "span",
            {
              staticClass: "color-black font-weight-bold",
              class: _vm.orderReturnVo.receiptType
                ? _vm.receiptTypeMapText[_vm.orderReturnVo.receiptType]
                    .textColor
                : ""
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.orderReturnVo.receiptType
                      ? _vm.receiptTypeMapText[_vm.orderReturnVo.receiptType]
                          .goodsStatus
                      : "-"
                  ) +
                  " "
              )
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "mt-10" },
          [
            _vm._v(" 退款理由： "),
            _c("span", { staticClass: "color-red font-weight-bold" }, [
              _vm._v(_vm._s(_vm.orderReturnVo.returnsTypeValue))
            ]),
            _vm.orderProductInfoVo.ratingCompanyId !== 1
              ? _c(
                  "a-button",
                  {
                    staticClass: "ml-20",
                    attrs: { type: "danger", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.handleChangeReturnPriceReason(
                          _vm.orderReturnVo.returnsType,
                          _vm.orderReturnVo.returnsTypeValue
                        )
                      }
                    }
                  },
                  [_vm._v("修改退款理由")]
                )
              : _vm._e(),
            _c(
              "a-button",
              {
                staticClass: "ml-20",
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.handleChangeLogisticsPrice(_vm.orderReturnVo)
                  }
                }
              },
              [_vm._v("修改退货运费")]
            ),
            _vm.orderReturnVo.isShowDelivery
              ? _c(
                  "a-button",
                  {
                    staticClass: "ml-20",
                    attrs: { type: "danger", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.handleSelectSellerNotCanSendProduct(
                          _vm.orderReturnVo
                        )
                      }
                    }
                  },
                  [_vm._v("无法交付")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "mt-10" }, [
          _vm._v(" 退款类型： "),
          _c("span", { staticClass: "color-red font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.orderReturnVo.receiptType
                    ? _vm.receiptTypeMapText[_vm.orderReturnVo.receiptType]
                        .returnPriceType
                    : "-"
                ) +
                " "
            )
          ])
        ]),
        _vm.orderReturnVo.returnsFile
          ? _c(
              "div",
              { staticClass: "mt-10 flex-start-center" },
              [
                _vm._v(" 买家退款凭证： "),
                _vm._l(_vm.orderReturnVo.returnsFile, function(img, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "w-50 h-50 flex-center-center" },
                    [
                      _c("img", {
                        staticClass: "w-100-w h-100-h",
                        attrs: { src: img, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImg(
                              _vm.orderReturnVo.returnsFile,
                              index
                            )
                          }
                        }
                      })
                    ]
                  )
                }),
                _vm.orderReturnVo.videoUrl
                  ? _c(
                      "a-button",
                      {
                        staticClass: "ml-",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToShowVideo(
                              _vm.orderReturnVo.videoUrl
                            )
                          }
                        }
                      },
                      [_vm._v("查看视频")]
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm.orderReturnVo.returnsReason
          ? _c("div", { staticClass: "mt-10" }, [
              _vm._v(" 买家退款补充描述： "),
              _c("span", { staticClass: "color-black font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.orderReturnVo.returnsReason) + " ")
              ])
            ])
          : _vm._e(),
        _vm.orderReturnVo.returnsOfService
          ? _c("div", { staticClass: "mt-10" }, [
              _vm._v(" 其他： "),
              _c("span", { staticClass: "color-black font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.orderReturnVo.returnsOfService) + " ")
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "mt-30 flex-start-start" }, [
          _c(
            "div",
            { staticClass: "flex-colum-start-start" },
            [
              _c("div", { staticClass: "font-weight-bold color-black" }, [
                _vm._v("买家赔付信息")
              ]),
              _vm._l(_vm.buyerCompensationInformation, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flex-start-center mt-10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-start-center font-weight-bold w-250"
                      },
                      [_vm._v(_vm._s(index))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-start-center font-weight-bold color-red w-50"
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "flex-colum-start-start ml-100" },
            [
              _c("div", { staticClass: "font-weight-bold color-black" }, [
                _vm._v("卖家赔付信息")
              ]),
              _vm._l(_vm.sellerCompensationInformation, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flex-start-center mt-10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-start-center font-weight-bold w-250"
                      },
                      [_vm._v(_vm._s(index))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-start-center font-weight-bold color-red w-50"
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ]),
      _c("ChangeReturnPriceReasonPopup", {
        ref: "changeReturnPriceReasonPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("ChangeReturnLogisticsPricePopup", {
        ref: "changeReturnLogisticsPricePopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("SellerNotCanSendProductPopup", {
        ref: "sellerNotCanSendProductPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }