<template>
  <a-modal v-model="showPopup" :width='500' title="查看举证/留言列表" :footer="null">
    <div class="voucher-list" v-if="voucherList && voucherList.length">
      <div class="voucher-list-item" v-for="(item,index) of voucherList" :key="index">
        <div class="voucher-list-left">{{ index + 1 }}</div>
        <div class="voucher-list-right">
          <div class="font-weight-bold color-black">{{ computedShowStepTitle(item) }}<span class="color-gray">{{ item.createTime }}</span></div>
          <div class="font-video-img">
            <img class="w-50 h-50 mr-20" @click="handleShowBigImg(item.proofImageList, ind)" v-for="(img, ind) in item.proofImageList" :key="ind" :src="img" alt=""/>
            <a-button v-if="item.proofVideoList" @click="handleGoToShowVideo(item.proofVideoList[0])">查看视频</a-button>
          </div>
        </div>
      </div>
    </div>
    <a-empty v-else />
  </a-modal>
</template>
<script>
import {getUserVoucherListApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import _returnOrderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_returnOrderDetailMixin"

export default {
  mixins: [_returnOrderDetailMixin],
  data() {
    return {
      voucherList: [],
      showPopup: false,
      identityTypeMapText: {
        0: '买家举证',
        1: '卖家举证'
      }
    }
  },
  computed: {
    computedShowStepTitle() {
      return (item) => {
        return (this.identityTypeMapText[item.identityType] || '客服') + (`【${item.userName}---${item.userId}】`)
      }
    }
  },
  methods: {
    async show(orderId) {
      await this.getVoucherList(orderId)
      this.showPopup = true
    },
    async getVoucherList(orderId) {
      this.$loading.show()
      const res = await getUserVoucherListApi({orderId})
      this.$loading.hide()
      if (res.status !== '200') return
      this.voucherList = res.data
    },
    /** 查看视频 */
    handleGoToShowVideo(videoUrl) {
      window.open(videoUrl, '_blank')
    },
  }
}
</script>
<style scoped lang="scss">
.voucher-list {
  width: 100%;
}
.voucher-list-item {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .voucher-list-left {
    margin-right: 20px;
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #32788A;
  }

  .voucher-list-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>